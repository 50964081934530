.chartHili {
  position: relative;
  display: inline-block;
  color: #fff;
}
.chartHilipic {
  position: relative;
  display: inline-block;
  color: #fff;
  background-color: #003d99;
}
.chartHili::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #0066ff;
  z-index: -1;
  transition: width 1s ease-in-out;
}
@media (max-width: 1120px) {
  .chartHilipic {
    font-size: 4vw;
  }
}

.chartHili.highlight::before {
  width: 100%;
}

.graph-container {
  width: 100%;
  height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 90px;
  margin-bottom: 158px;
  @media (max-width: 1120px) {
    & {
      height: 400px;
      margin-bottom: 100px;
    }
  }
  @media (max-width: 768px) {
    & {
      height: 250px;
      margin-bottom: 80px;
    }
  }
}
.chart-container {
  width: 100%;
  height: 90%;
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  position: relative;
  > p {
    position: absolute;
    color: white;
    font-size: 26px;
    font-weight: 700;
    line-height: 35px;
    &:nth-of-type(1) {
      left: 10%;
      top: 10%;
    }
    &:nth-of-type(2) {
      right: 10%;
      bottom: 35%;
    }
  }
  @media (max-width: 1120px) {
    > p {
      font-size: 2.5vw;
      line-height: 4vw;
      &:nth-of-type(1) {
        left: 5%;
        top: 5%;
      }
      &:nth-of-type(2) {
        right: 5%;
        bottom: 40%;
      }
    }
  }
}
.chart-box {
  width: 80%;
  margin: 0 auto;
  height: 75%;
  position: relative;
  @media (max-width: 1120px) {
    & {
      height: 55%;
    }
  }
  .data-point {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    transform: translate(-50%, 50%);
    @media (max-width: 1120px) {
      & {
        width: 2vw;
        height: 2vw;
      }
    }
    &:nth-of-type(1) {
      background-color: #0077cc;
      .point-label {
        display: none;
      }
    }
    &:nth-of-type(2) {
      background-color: #3ba0ec;
      .point-label {
        color: rgba(147, 228, 245, 0.7);
      }
    }
    &:nth-of-type(3) {
      background-color: #6ec8f1;
      .point-label {
        color: #93e4f5;
      }
    }
    &:nth-of-type(4) {
      width: 150px;
      height: 150px;
      background-color: rgba(0, 102, 255, 0.5);
      @media (max-width: 1120px) {
        & {
          width: 17vw;
          height: 17vw;
        }
      }
      .point-label {
        color: white;
        font-size: 30px;
        font-weight: 700;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 125px;
        height: 125px;
        line-height: 125px;
        border-radius: 50%;
        background-color: #06f;
        @media (max-width: 1120px) {
          & {
            font-size: 3vw;
            line-height: 13vw;
            width: 13vw;
            height: 13vw;
          }
        }
      }
    }
    .point-label {
      position: absolute;
      width: 100px;
      text-align: center;
      transform: translate(-40%, -200%);
      font-size: 22px;
      font-weight: 700;
      @media (max-width: 1120px) {
        & {
          font-size: 2.2vw;
          transform: translate(-60%, -150%);
        }
      }
    }
  }
}
.text-box {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  @media (max-width: 1120px) {
    & {
      width: 90%;
      padding-top: 55px;
      margin: 0 auto;
      gap: 0;
    }
  }
  @media (max-width: 768px) {
    & {
      width: 90%;
      padding-top: 30px;
      margin: 0 auto;
      gap: 0;
    }
  }
  > div {
    text-align: center;
    h4 {
      font-size: 18px;
      font-weight: 500;
      @media (max-width: 1120px) {
        & {
          font-size: 1.8vw;
          line-height: 2.6vw;
        }
      }
    }
    p {
      font-size: 14px;
      font-weight: 500;
      @media (max-width: 1120px) {
        & {
          font-size: 1.4vw;
        }
      }
    }
  }
  svg {
    padding: 3%;
  }
}
.date-box {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  color: #909090;
  font-size: 18px;
  font-weight: 400;
  @media (max-width: 1120px) {
    & {
      font-size: 2vw;
    }
  }
}

// 분리
.Third {
  background-size: cover;
  background-position: center;
  .sectionSubTitle {
    color: #d9d9d9;
  }
  .slick-list {
    margin-right: -20px;
    height: 250px;
    @media (max-width: 1120px) {
      height: 200px;
    }
    @media (max-width: 768px) {
      height: 100px;
    }
    .slick-track {
      height: 100%;
      .slick-slide {
        padding-right: 20px;
        height: 100%;
        > div {
          border: 1px solid white;
          height: calc(100% - 2px);
          position: relative;
          border-radius: 2vw;
          overflow: hidden;

          img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            cursor: pointer;
          }
          .cardDiv {
            text-align: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            .highlighted-text {
              color: #ffffff;
              font-size: 1.7rem;
              margin-bottom: 15px;
              font-weight: 700;
              @media (max-width: 1120px) {
                font-size: 12px;
                margin-bottom: 5px;
              }
              .highlight {
                color: #0070f3;
              }
            }
            .partnerBtn {
              color: #ffffff;
              background-color: #3288ff;
              width: 171px;
              height: 40px;
              border-radius: 20px;
              border: none;
              cursor: pointer;
              font-size: 14px;
              font-weight: 700;
              margin: 0;
              padding: 0;
              @media (max-width: 1120px) {
                width: 100px;
                height: 20px;
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
  .slick-dots {
    width: 100%;
    bottom: -50px;
    li {
      background: #797979;
      border-radius: 100%;
      width: 13px;
      height: 13px;
      @media (max-width: 1120px) {
        width: 8px;
        height: 8px;
      }
      button {
        width: 100%;
        height: 100%;
        background-color: none;
        &::before {
          color: #797979;
          width: 50%;
          height: 50%;
          transform: translate(30%, -100%);
        }
      }
      &.slick-active {
        background: white;
        button {
          &::before {
            color: white;
          }
        }
      }
    }
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #101014;
  padding-bottom: 100px;
  .sectionInner {
    padding-bottom: 50px;
  }

  .ThirdHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    h6 {
      font-size: 26px;
      font-weight: 700;
      @media (max-width: 1120px) {
        & {
          font-size: 4.5vw;
          font-weight: 700;
        }
      }
    }
  }
  .sectionTitle {
    color: #fff;
    margin-bottom: 66px;
    white-space: nowrap;
    @media (max-width: 1120px) {
      & {
        margin-bottom: 30px;
      }
    }
  }
}

// 원호 추가 20240719

.threeModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 배경 그림자 */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
}

.threeModal-content {
  width: 50%;
  background: white;
  // padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  cursor: default;
  top: -20px; /* 슬릭 이미지 상단 20px 위치 */
  @media (max-width: 1120px) {
    & {
      width: 100%;
      border-radius: 0px;
    }
  }
}

.threeModal-content img {
  width: 100%;
  height: auto;
  display: block; /* 이미지가 중앙에 위치하도록 블록 요소로 설정 */
  margin: 0 auto; /* 수평 중앙 정렬 */
}

.threeModal-close {
  padding: 0 6px;
  position: absolute;
  top: 1%;
  right: 3px;
  background: none;
  font-size: 20px;
  border: none;
  cursor: pointer;
  color: #909090;
}
.threeModal-close:hover {
  background-color: #d9d9d9;
}
@media (max-width: 1120px) {
  .threeModal-close {
    top: 1.6%;
    right: 1px;
    font-size: 2vw;
  }
}
