.ninthContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #001a41;
  background-size: cover;
  background-position: center;
  .ninthIntro {
    color: #d9d9d9;
  }
  .ninthToptitle {
    color: #fff;
    font-weight: 700;
    span {
      color: #93e4f5;
    }
  }
  .ninthBottitle {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 70px;
    color: #fff;
    @media (max-width: 1120px) {
      & {
        margin-top: 40px;
        margin-bottom: 30px;
        font-size: 5vw;
      }
    }
  }
  .ninthCardBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    @media (max-width: 1120px) {
      & {
        flex-direction: column;
        gap: 5vw;
      }
    }
  }
}
.ninthBox {
  border: none;
  padding: 20px;
  width: 49%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
  @media (max-width: 1120px) {
    & {
      width: 100%;
      padding: 4vw;
    }
  }
}

.ninthTitle {
  font-size: 26px;
  font-weight: 700;
  line-height: 31.21px;
  text-align: center;
  color: #003d99;
  margin-bottom: 10px;
  @media (max-width: 1120px) {
    & {
      font-size: 4.5vw;
      margin-bottom: 1.5vw;
    }
  }
  @media (max-width: 768px) {
    & {
      font-size: 4.8vw;
      margin-bottom: 0.8vw;
    }
  }
}
.ninthContent {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.62px;
  text-align: center;
  color: black;
  @media (max-width: 1120px) {
    & {
      font-size: 3.5vw;
      line-height: 4vw;
    }
  }
  @media (max-width: 768px) {
    & {
      font-size: 3.7vw;
      line-height: 5vw;
    }
  }
}
