#workSection {
  .slider-container {
    margin: -150px 0 150px;
    @media (max-width: 1120px) {
      & {
        margin: -170px 0 80px;
      }
    }
  }
  .slick-list {
    margin-right: -20px;
    height: 168px;
    @media (max-width: 1120px) {
      height: 150px;
      margin-bottom: 50px;
      margin-top: 90px;
    }
    @media (max-width: 768px) {
      height: 90px;
      margin-top: 90px;
      margin-bottom: 50px;
    }
    .slick-track {
      height: 100%;
      .slick-slide {
        padding-right: 20px;
        height: 100%;
        > div {
          height: calc(100% - 2px);
          position: relative;
          overflow: hidden;
          img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
          }
        }
      }
    }
  }
}
.sevenHeader {
  display: flex;
  flex-direction: column;
  > h6 {
    color: rgba(0, 0, 0, 0.5);
  }
  > h2 {
    color: #0b1115;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 63px;
    span {
      color: #003d99;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    @media (max-width: 1120px) {
      & {
        margin: 0 0 40px;
      }
    }
  }

  > h3 {
    color: #0b1115;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 65px;
    @media (max-width: 1120px) {
      & {
        font-size: 4vw;
        margin: 0 0 30px;
      }
    }
  }
}
.boxWrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 112px;
  @media (max-width: 1120px) {
    & {
      // flex-direction: column;
      // justify-content: space-between;
      scale: 1.09;
      flex-wrap: nowrap;
      margin-bottom: 42px;
      // gap: 20px;
    }
  }
  li {
    width: 32%;
    border-radius: 2px;
    @media (max-width: 1120px) {
      & {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 3%;
      }
    }
    .navyBox {
      height: 209px;
      background: linear-gradient(180deg, #003d99 0%, #003d99 100%);
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      position: relative;
      @media (max-width: 1120px) {
        & {
          width: 96%;
          height: 21vw;
        }
      }
      h4 {
        color: #fff;
        text-align: center;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 6px;
        @media (max-width: 1120px) {
          & {
            font-size: 3vw;
            margin-bottom: 6px;
          }
        }
      }
      h3 {
        color: #fff;
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        @media (max-width: 1120px) {
          & {
            font-size: 3.2vw;
          }
        }
      }
      h6 {
        color: rgba(255, 255, 255, 0.5);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 13px;
        @media (max-width: 1120px) {
          & {
            font-size: 2vw;
            margin-top: 1.7vw;
          }
        }
      }
      .etcText {
        width: 100%;
        color: rgba(11, 17, 21, 0.5);
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        left: 0;
        bottom: -15px;
        @media (max-width: 1120px) {
          & {
            font-size: 1.7vw;
            text-align: center;
          }
        }
      }
    }
    .whiteBox {
      margin-top: 50px;
      background-color: white;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      @media (max-width: 1120px) {
        & {
          width: 50%;
          float: right;
          margin: 0;
          align-items: center;
          align-content: center;
        }
      }
      h3 {
        width: 100%;
        border-bottom: 1px solid #ced7e4;
        margin: 18px 0 20px 0;
        font-size: 26px;
        padding-bottom: 20px;
        span {
          display: inline-block;
          width: 50px;
          height: 50px;
          border-radius: 100%;
          border: 1px solid #003d99;
          color: #003d99;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: 50px;
          text-align: center;
          margin-right: 25px;
          margin-left: 5%;
        }
        @media (max-width: 1120px) {
          & {
            font-size: 4vw;
            padding-bottom: 2.5vw;
            margin: 0 0 10px 0;
            span {
              width: 7vw;
              height: 7vw;
              line-height: 7vw;
              font-size: 3.8vw;
              margin-right: 2vw;
            }
          }
        }
      }
      div {
        color: #000;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: -1px;
        padding-left: 12.5%;
        @media (max-width: 1120px) {
          & {
            font-size: 2.8vw;
            line-height: 3.5vw;
            padding-left: 10vw;
            width: 100%;
          }
        }
      }
    }
  }
}
.thridnewimgFour {
  @media (max-width: 1120px) {
    scale: 1.15;
    margin: 28px 0 70px;
  }
}
