// 분리
.ThirdBox {
  background: linear-gradient(to top, #002660 50%, #00317a 50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #101014;
  padding-bottom: 100px;
  .sectionInner {
    padding-bottom: 50px;
  }
  .ThirdHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    h6 {
      font-size: 26px;
      font-weight: 700;
      @media (max-width: 1120px) {
        & {
          font-size: 4.5vw;
          font-weight: 700;
        }
      }
    }
  }
  .sectionSubTitle {
    color: #d9d9d9;
  }
  .sectionTitle {
    color: #fff;
    margin-bottom: 31px;
    white-space: nowrap;
    @media (max-width: 1120px) {
      & {
        margin-bottom: 30px;
      }
    }
  }

  h6 {
    span.chartHilipic {
      position: relative;
      display: inline-block;
      color: #fff;
      background-color: #0066ff;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: #0066ff;
        z-index: -1;
        transition: width 1s ease-in-out;
      }
      @media (max-width: 1120px) {
        & {
          font-size: 4vw;
        }
      }
    }
  }
  .graphImgBox {
    width: 80%;
    margin: 80px auto;
    @media (max-width: 1120px) {
      & {
        width: 100%;
        margin: 50px auto;
      }
    }
    img {
      width: 100%;
    }
  }
}

///이미지슬라이드 관련
.ThirdBox {
  .slick-list {
    margin-right: -20px;
    height: 250px;
    @media (max-width: 1120px) {
      height: 200px;
    }
    @media (max-width: 768px) {
      height: 100px;
    }
    .slick-track {
      height: 100%;
      .slick-slide {
        padding-right: 20px;
        height: 100%;
        > div {
          border: 1px solid white;
          height: calc(100% - 2px);
          position: relative;
          border-radius: 2vw;
          overflow: hidden;

          img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            cursor: pointer;
          }
          .cardDiv {
            text-align: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            .highlighted-text {
              color: #ffffff;
              font-size: 1.7rem;
              margin-bottom: 15px;
              font-weight: 700;
              @media (max-width: 1120px) {
                font-size: 12px;
                margin-bottom: 5px;
              }
              .highlight {
                color: #0070f3;
              }
            }
            .partnerBtn {
              color: #ffffff;
              background-color: #3288ff;
              width: 171px;
              height: 40px;
              border-radius: 20px;
              border: none;
              cursor: pointer;
              font-size: 14px;
              font-weight: 700;
              margin: 0;
              padding: 0;
              @media (max-width: 1120px) {
                width: 100px;
                height: 20px;
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
  .slick-dots {
    width: 100%;
    bottom: -50px;
    li {
      background: #797979;
      border-radius: 100%;
      width: 13px;
      height: 13px;
      @media (max-width: 1120px) {
        width: 8px;
        height: 8px;
      }
      button {
        width: 100%;
        height: 100%;
        background-color: none;
        &::before {
          color: #797979;
          width: 50%;
          height: 50%;
          transform: translate(30%, -100%);
        }
      }
      &.slick-active {
        background: white;
        button {
          &::before {
            color: white;
          }
        }
      }
    }
  }
}

// 원호 추가 20240719
.threeModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 배경 그림자 */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
}

.threeModal-content {
  width: 50%;
  background: white;
  // padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  cursor: default;
  top: -20px; /* 슬릭 이미지 상단 20px 위치 */
  @media (max-width: 1120px) {
    & {
      width: 100%;
      border-radius: 0px;
    }
  }
}

.threeModal-content img {
  width: 100%;
  height: auto;
  display: block; /* 이미지가 중앙에 위치하도록 블록 요소로 설정 */
  margin: 0 auto; /* 수평 중앙 정렬 */
}

.threeModal-close {
  padding: 0 6px;
  position: absolute;
  top: 1%;
  right: 3px;
  background: none;
  font-size: 20px;
  border: none;
  cursor: pointer;
  color: #909090;
}
.threeModal-close:hover {
  background-color: #d9d9d9;
}
@media (max-width: 1120px) {
  .threeModal-close {
    top: 1.6%;
    right: 1px;
    font-size: 2vw;
  }
}
