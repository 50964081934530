.section9 {
  .slick-list {
    @media (max-width: 1120px) {
      margin-right: -20px;
      height: 90px;
      margin-bottom: 50px;
      .slick-track {
        height: 100%;
        .slick-slide {
          padding-right: 20px;
          height: 100%;
          > div {
            height: calc(100% - 2px);
            position: relative;
            overflow: hidden;
            img {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
            }
          }
        }
      }
    }
  }
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.5) 32%,
    rgba(245, 250, 253, 0.5) 65.5%,
    rgba(235, 245, 251, 0.5) 100%
  );
  text-align: center;
  h2 {
    color: #003d99;
    padding-bottom: 20px;
    @media (max-width: 1120px) {
      & {
        padding-bottom: 40px;
      }
    }
  }
  h3 {
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    padding-bottom: 80px;
    span {
      font-weight: 800;
    }
    @media (max-width: 1120px) {
      & {
        font-size: 4vw;
        line-height: 5.5vw;
        padding-bottom: 25px;
      }
    }
  }
  .mapSpecial {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media (max-width: 1120px) {
      & {
        gap: 40px;
      }
    }
  }
  .map {
    width: 50%;
    @media (max-width: 1120px) {
      & {
        width: 100%;
      }
    }
    img {
      width: 100%;
    }
  }
  .special {
    width: 45%;
    @media (max-width: 1120px) {
      & {
        width: 100%;
      }
    }
    > div {
      padding: 10% 5%;
      border-top: 9px solid #3288ff;
      box-shadow: 0px 10px 30px #ced7e4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (max-width: 1120px) {
        & {
          border-top: 5px solid #3288ff;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          padding: 5%;
        }
      }
      h4 {
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        margin: 0;
        padding-bottom: 20px;
        span {
          color: #06f;
        }
        @media (max-width: 1120px) {
          & {
            font-size: 3vw;
            line-height: 4vw;
            padding-bottom: 0;
            span {
              font-size: 5vw;
              margin-top: 1vw;
              display: inline-block;
            }
          }
        }
      }
      ul {
        display: flex;
        flex-direction: column;

        li {
          font-size: 18px;
          font-weight: 500;
          width: 100%;
          text-align: left;
          line-height: 30px;
          height: auto;
          span {
            font-size: 14px;
            display: inline;
          }
          @media (max-width: 1120px) {
            & {
              font-size: 2.5vw;
              line-height: 4vw;
              span {
                font-size: 2vw;
                display: inline;
              }
            }
          }
        }
      }
    }
    p {
      width: 100%;
      text-align: right;
      color: rgba(11, 17, 21, 0.5);
      margin-top: 16px;
      @media (max-width: 1120px) {
        & {
          font-size: 2vw;
          margin-top: 2vw;
        }
      }
    }
  }
  // 여백 설정 슬릭
  .slider-container {
    display: flex;
    flex-direction: column;
    & > div {
      height: 100px;
      border: none;
    }
    @media (max-width: 1120px) {
      & > div {
        height: 45px;
      }
    }
  }
}
