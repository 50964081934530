.newsecdetailtitle {
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 24px;
  @media (max-width: 1120px) {
    font-size: 4.5vw;
    margin: 0;
  }
}
.newsecdetaildetail {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1px;
  margin: 11px 0px 37px 24px;
  @media (max-width: 1120px) {
    font-size: 3.8vw;
    line-height: 4.8vw;
    margin: 11px 0 0 0;
  }
}
///left
.newsecLeft {
  display: flex;
  flex-direction: column;
  @media (max-width: 1120px) {
    margin-bottom: 30px;
  }
}

.newsecLeftDetail.sectionTitle {
  width: 444px;
  color: #000;
  text-align: left;
  // font-size: 48px;
  // font-style: normal;
  // font-weight: 700;
  // line-height: normal;
  @media (max-width: 1120px) {
    width: 100%;
    // font-size: 5vw;
    // line-height: 6.5vw;
    text-align: center;
  }
}

//right
.newsecRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .displayFlexRow {
    display: flex;
    flex-direction: row;

    margin: 47px 0px 0px 77px;
    border-bottom: 1px solid #ced7e4;
    width: 90%;
    @media (max-width: 1120px) {
      width: 80%;
      margin: 0 0 0;
      padding: 20px 0;
      opacity: 0; /* 초기에는 숨깁니다 */
      transform: translateY(20px); /* 초기에는 아래로 내려갔다가 나타납니다 */
      justify-content: left;
      align-items: center;
      gap: 20px;
      &:nth-of-type(1) {
        transition: opacity 0.8s ease, transform 0.8s ease;
      }
      &:nth-of-type(2) {
        transition: opacity 1.5s ease, transform 1.5s ease;
      }
      &:nth-of-type(3) {
        transition: opacity 2.2s ease, transform 2.2s ease;
        border: none;
      }
    }
  }
  .pc_newsecNumber {
    color: #003d99;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: block;
    @media (max-width: 1120px) {
      font-size: 4.5vw;
      display: none;
    }
  }
  .mobile_newsecIcon {
    display: none;
    width: 25%;
    img {
      width: 100%;
    }
    @media (max-width: 1120px) {
      display: block;
    }
  }
}

.mobile-view {
  .displayFlexRow.appear {
    opacity: 1; /* 나타날 때 opacity를 1로 설정하여 보이게 합니다 */
    transform: translateY(
      0
    ); /* translateY를 0으로 설정하여 위로 올라오는 효과를 줍니다 */
  }
}

.newsecond > .sectionInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  //height: 785px;
  background: #fff;
  @media (max-width: 1120px) {
    flex-direction: column;
  }
}
.newsecLeftTitle {
  width: 222px;
  height: 31px;
  color: #3288ff;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 19px;
  span {
    font-weight: 500;
    margin-left: -6px;
  }
  @media (max-width: 1120px) {
    width: 100%;
    text-align: center;
    font-size: 5vw;
    span {
      margin-left: -1vw;
    }
  }
}
