.tenContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 40px;
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  &.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  .sectionSubTitle {
    color: rgba(0, 0, 0, 0.5);
  }
  .sectionTitle {
    margin-bottom: 116px;
    @media (max-width: 1120px) {
      & {
        margin-bottom: 40px;
      }
    }
  }
  .tenCardBox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    gap: 0 20px;
    @media (max-width: 1120px) {
      & {
        flex-direction: column;
        align-items: center;
        gap: 50px 0;
        > div {
          width: 100%;
        }
      }
    }
    .tenCard {
      &:nth-of-type(2n + 2) {
        margin-top: 60px;
      }
      p {
        width: 100%;
        text-align: center;
        margin-bottom: 31px;
        font-size: 20px;
        font-weight: 500;
        color: #003d99;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-right: 10px;
        }
        @media (max-width: 1120px) {
          & {
            font-size: 4.5vw;
          }
        }
      }
      .imgBox {
        overflow: hidden;
        background: #fff;
        padding: 10px;
        box-shadow: 0px 4px 30px 0px #ced7e4;
        img {
          width: 100%;
        }
      }
    }
  }
}
