.video-container {
  position: relative;
  width: 100%;
  height: calc(100vh + 100px);
  overflow: hidden;
  background-color: #0b1115;
  background-position: center; /* 이미지를 가운데에 위치 */
  background-size: cover; /* 이미지를 요소 크기에 맞게 조절 */
  background-repeat: no-repeat; /* 이미지 반복 없음 */
  .video-background {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .content {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -30%);
    color: white;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
  }
  .AddArticle {
    color: #fff;
    font-size: 38px;
    font-weight: 700;
    white-space: nowrap;
    span {
      font-size: 22px;
      font-weight: 500;
    }
  }
  .line {
    width: 3px;
    height: 82px;
    background: rgba(217, 217, 217, 0.5);
    margin: 39px 0 42px 0;
  }
  .MainArticle {
    .highlight {
      color: #fff;
      text-align: center;
      // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 74px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin-bottom: 7px;
      background-color: #0066ff;
      /* 모든 장치에 대한 기본 스타일 (갤럭시 포함) */
      // .highlight {
      //   color: #fff;
      //   text-align: center;
      //   text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      //   font-size: 74px;
      //   font-style: normal;
      //   font-weight: 800;
      //   line-height: normal;
      //   margin-bottom: 7px;
      //   background-color: #0066ff;
      // } /* 아이폰에만 적용될 스타일 */
      @media screen and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 2) {
        .highlight {
          text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  .SubArticle {
    color: #fff;
    font-size: 65px;
    font-style: normal;
    font-weight: 500;
    line-height: 100px;
    margin: 0;
    white-space: nowrap;
  }

  .MSO {
    width: 100%;
    margin-top: -170px;
    p {
      font-family: "Poppins";
      font-size: 18px;
      color: rgba(255, 255, 255, 0.6);
      width: 100%;
      margin-top: 200px;
      margin-bottom: 20px;
    }
    > div {
      width: 50px;
      height: 50px;
      margin: 0 auto;
      border-radius: 50%;
      background-color: white;
      text-align: center;
      line-height: 55px;
    }
  }
  @media (max-width: 1120px) {
    .content {
      width: 80%;
      top: 40%;
    }
    .AddArticle {
      color: #fff;
      font-size: 4.5vw;
      span {
        font-size: 3vw;
      }
    }
    .line {
      height: 50px;
      margin: 29px 0 32px 0;
    }
    .MainArticle {
      .highlight {
        font-size: 8vw;
        padding: 3px 5px;
      }
    }
    .SubArticle {
      font-size: 8vw;
      line-height: 9.5vw;
      word-break: break-word;
      white-space: normal;
    }
    .MSO {
      margin-top: 8vw;

      p {
        font-size: 13px;
        margin-top: 20px;
        margin-bottom: 30px;
      }
      > div {
        width: 30px;
        height: 30px;
        line-height: 35px;
      }
    }
  }
  @media (max-width: 560px) {
    .MSO {
      margin-top: 25vw;
    }
  }
}
