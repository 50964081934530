/* @font-face {
  font-family: "Pretendard";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff2")
      format("woff2"),
    url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
} */
@font-face {
  font-family: "Pretendard";
  src: url(../public/Pretendard.woff2) format("woff2"),
    url(../public/Pretendard.woff) format("woff"),
    url(../public/Pretendard.ttf) format("truetype"),
    url(../public/Pretendard.otf) format("opentype");
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
}
body {
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  scrollbar-width: none; /* Firefox */
}

.mainHome {
  font-family: "Pretendard", sans-serif;
}
.mainHome button {
  font-family: "Pretendard", sans-serif;
}
.iphone {
  font-family: initial !important;
}
.iphone button {
  font-family: initial !important;
}
.iphone .marginBottom3px {
  padding: 0px 5px !important;
}

/* 웹킷 기반 브라우저에서 스크롤바 숨기기 */
body::-webkit-scrollbar {
  display: none;
}
ul,
li,
ol {
  list-style: none;
}
.fontEn {
  font-family: "Poppins";
}

.pc-br {
  display: block;
}
.mobile-br {
  display: none;
}

section .sectionTitle {
  font-size: 48px;
  /* color: #0b1115; */
  text-align: center;
  font-weight: 700;
}
section .sectionSubTitle {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 25px;
}

@media (max-width: 1120px) {
  section .sectionTitle {
    font-size: 5vw;
    line-height: 6.5vw;
  }
  section .sectionSubTitle {
    text-align: center;
    font-size: 3vw;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .pc-br {
    display: none;
  }
  .mobile-br {
    display: block;
  }
  .moblieHightlight {
    background-color: #0066ff;
    color: white;
    line-height: 2rem;
    padding: 1.5px 2px;
  }
}
@media (max-width: 768px) {
  section .sectionTitle {
    font-size: 7vw;
    line-height: 8vw;
  }
  section .sectionSubTitle {
    text-align: center;
    font-size: 4.7vw;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .pc-br {
    display: none;
  }
  .mobile-br {
    display: block;
  }
}
