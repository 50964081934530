header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 80px;
  position: fixed;
  z-index: 10;
}
header.scrolled {
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
}
header.scrolled ul li {
  color: #000;
}
.headerInner {
  width: 1120px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}
.headerInner h1 {
  z-index: 10;
  height: 60%;
}
.headerInner h1 img {
  height: 100%;
}
nav {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
}
nav ul li {
  width: 137px;
  height: 100%;
  line-height: 100%;
  cursor: pointer;
  transition: color 0.3s;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav ul li a {
  font-weight: 700;
  display: inline;
  font-size: 16px;
  font-family: "Poppins";
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
nav ul li:hover {
  color: #555;
}
nav ul li.scrolled:hover {
  color: #eeeeee;
}
.hamburger {
  display: none;
}
@media (max-width: 1120px) {
  header {
    height: 70px;
  }
  header.scrolled .headerInner .hamburger > span {
    background-color: #909090;
  }
  .headerInner {
    width: 90%;
  }
  .hamburger {
    width: 24px;
    height: 18px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    z-index: 10;
  }
  .hamburger > span {
    width: 100%;
    height: 2px;
    background-color: white;
  }
  .hamburger.open span {
    background-color: #909090;
  }
  .headerInner.open nav {
    transform: translateX(0%);
    left: 0;
  }
  .headerInner.open ul li {
    color: #000;
  }
  nav {
    transition: transform 0.5s ease-in-out;
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: white;
    transform: translateX(100%);
    padding-top: 70px;
    top: 0;
  }
  nav ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    text-align: left;
    border-top: 1px solid #909090;
  }
  nav ul li {
    width: 100%;
    height: 75px;
    border-bottom: 1px solid #909090;
  }
  nav ul li a {
    justify-content: flex-start;
    padding-left: 8%;
    font-size: 3vw;
  }
}
@media (max-width: 768px) {
  header {
    height: 60px;
  }
  .headerInner nav {
    padding-top: 60px;
  }
  .headerInner nav ul li {
    height: 65px;
  }
}

// Layout.scss
.layOutFixedButtons {
  position: fixed;
  bottom: 50%;
  right: 45px;
  transform: translateY(50%);
  display: flex;
  flex-direction: column;
}
.layOutButton {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-bottom: 22px;
  img {
    display: block; // 이미지를 블록 요소로 설정
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.layOutmodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .layoutmodalContent {
    position: relative; // 상대적 위치 설정 추가
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
    height: 600px;
    .closeButton {
      position: absolute;
      top: 10px;
      right: 10px;
      border: none;
      background: none;
      font-size: 20px;
      padding: 0 5px;
      cursor: pointer;
      color: #909090;
    }
    .closeButton:hover {
      background-color: #d9d9d9;
    }
  }
}

@media (max-width: 1120px) {
  .layOutFixedButtons {
    bottom: 30px;
    transform: translateY(0%);
    right: 15px;
    display: flex;
    flex-direction: column;
    img {
      width: 50px;
      height: 50px;
      display: block; // 이미지를 블록 요소로 설정
    }
    .layOutButton {
      margin-bottom: 15px;
    }
  }
  .layoutmodalContent {
    width: 100%;
    height: 480px !important;
    img {
      width: 100%;
    }
    .closeButton {
      top: -7px !important;
      right: 2px !important;
    }
  }
}
