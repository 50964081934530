.eightThContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  .eightthBodysection {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }
  .eightthSection {
    width: 356px;
    height: 514px;
    padding: 20px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25);
    h3 {
      color: white;
      font-size: 30px;
      margin-bottom: 25px;
    }
    .eightthLine {
      width: 90%;
      height: 2px;
      background: rgba(255, 255, 255, 0.2);
    }
    .eightDescription {
      font-size: 20px;
      color: #fff;
      font-weight: 500;
      text-align: center;
      margin: 29px 0px 69px;
      letter-spacing: -1px;
    }
  }

  .sectionTitle {
    position: relative;
    margin-bottom: 98px;
    color: #0b1115;
    > span {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #0066ff;
        border-radius: 10px;
        left: 50%;
        top: 0%;
        transform: translate(-50%, -100%);
      }
    }
  }

  .eightFooter {
    margin-top: 72px;
    color: #0b1115;
    font-size: 26px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
    span {
      font-weight: 700;
    }
  }
  @media (max-width: 1120px) {
    .sectionTitle {
      margin-bottom: 30px;
      > span {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 1vw;
          height: 1vw;
          background-color: #0066ff;
          border-radius: 10px;
          left: 50%;
          top: 0%;
          transform: translate(-50%, -110%);
        }
      }
    }
    .eightthSection {
      width: 88%;
      height: 35vw;
      margin: 0;
      box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
      padding: 10px;
      h3 {
        font-size: 3vw;
        margin-bottom: 8px;
      }
      .eightDescription {
        font-size: 2.5vw;
        line-height: 3.5vw;
        color: #fff;
        font-weight: 500;
        text-align: center;
        margin: 10px 0px 5px;
        letter-spacing: -1px;
      }
    }
    & {
      background-position: center 50px; /* 이미지를 수평 중앙, 수직 20px 위치에 배치 */
      background-size: cover;
    }
    .eightthBodysection {
      gap: 25px;
      flex-direction: column;
    }
    .eightFooter {
      margin-top: 40px;
      font-size: 5vw;
      line-height: 7vw;
      text-align: center;
      span {
        font-weight: bold;
      }
    }
  }
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(130px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .fadeInUp {
    animation: fadeInUp 1.5s ease-out forwards;
  }
}
