.ThirdNewBox {
  background: linear-gradient(0deg, #fff 0%, #f5fafd 49%, #ebf5fb 100%);
  .ThirdNewBoxSet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .sectionSubTitle {
    color: rgba(0, 0, 0, 0.25);
  }
  .colorBlue3nwe {
    color: #06f;
    white-space: nowrap;
  }
  .ThirdNewBoxImg {
    margin: 58px 0;
    width: 31.8vw;
  }
  .thirdNewDetail {
    color: #0b1115;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 141%; /* 36.66px */
    letter-spacing: -1.04px;
  }
  @media (max-width: 1120px) {
    .ThirdNewBoxImg {
      width: 90%;
    }
    .thirdNewDetail {
      font-size: 3.7vw;
      white-space: nowrap;
    }
  }
}
.thirdNewDetailSpan {
  font-weight: 700;
}
