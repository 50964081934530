.funnelsMessase {
  text-align: center;
  font-size: 58px;
  background: linear-gradient(180deg, #f5f5f5 0%, #fff 100%);
}

.funnelsText {
  color: #0b1115;
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  margin-top: 90px;
  span {
    font-weight: bold;
    display: inline-block;
    margin-top: 50px;
  }
  @media (max-width: 1120px) {
    & {
      margin-top: 40px;
      font-size: 4vw;
      line-height: 5vw;
      > span {
        margin-top: 30px;
      }
    }
  }
}
.funnelsSurviesItems {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 90px;
  @media (max-width: 1120px) {
    & {
      width: 90%;
      margin: 0 auto;
      margin-top: 50px;
    }
  }
  .funnelsSurviesItem {
    width: 30%;
    aspect-ratio: auto 1/1;
    border-radius: 100%;
    overflow: hidden;
    cursor: pointer;
    background-position: center; /* 이미지를 가운데에 위치 */
    background-size: cover; /* 이미지를 요소 크기에 맞게 조절 */
    background-repeat: no-repeat; /* 이미지 반복 없음 */
    @media (max-width: 1120px) {
      & {
        width: 32%;
      }
    }
    // &:nth-of-type(1) {
    //   background-image: url("/Images/8/bg_open.png");
    // }
    // &:nth-of-type(2) {
    //   background-image: url("/Images/8/bg_online.png");
    // }
    // &:nth-of-type(3) {
    //   background-image: url("/Images/8/bg_mso.png");
    // }
    > div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h3 {
        font-size: 26px;
        color: white;
        margin: 0;
        @media (max-width: 1120px) {
          & {
            font-size: 2.7vw;
          }
        }
      }
      p {
        font-size: 16px;
        color: white;
        font-weight: 400;
        margin-top: 10px;
        @media (max-width: 1120px) {
          & {
            font-size: 11px;
            line-height: 15px;
            margin-top: 3px;
          }
        }
      }
    }
  }
}
.funnelsMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 26px;
  gap: 10px;
  margin-top: 100px;
  @media (max-width: 1120px) {
    & {
      font-size: 2.7vw;
      gap: 5px;
      margin-top: 50px;
    }
  }
  & > div {
    width: 100%;
    display: flex;
    div {
      padding: 2% 0%;
      @media (max-width: 1120px) {
        & {
          padding: 3% 0%;
        }
      }
      &:nth-of-type(1) {
        background-color: #003d99;
        color: white;
        text-align: center;
        font-weight: 600;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &:nth-of-type(2) {
        background-color: #ebf5fb;
        text-align: center;
        font-weight: 500;
        flex: 2;
        span {
          color: #0066ff;
          display: inline;
        }
        p {
          width: 100%;
          margin-top: 3px;
          @media (max-width: 1120px) {
            & {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
}
