.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 150px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.contact-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1120px) {
    & {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0;
    }
  }
}
.contact-info {
  flex: 1;
  padding-right: 20px;
  @media (max-width: 1120px) {
    & {
      padding-right: 0px;
      width: 100%;
      padding-bottom: 50px;
    }
  }
}
.contact-info h2 {
  margin: 0;
  padding: 0 0 30px 0;
  font-size: 58px;
  color: white;
  @media (max-width: 1120px) {
    & {
      padding: 0 0 20px 0;
      font-size: 8vw;
    }
  }
  @media (max-width: 768px) {
    & {
      padding: 0 0 20px 0;
      font-size: 10vw;
    }
  }
}
.contact-info ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  justify-content: flex-start;
  gap: 30px 0;
  @media (max-width: 1120px) {
    & {
      gap: 25px 0;
    }
  }
  @media (max-width: 768px) {
    & {
      gap: 20px 0;
    }
  }
}
.contact-info li {
  width: 100%;
  text-align: left;
  height: 30px;
  line-height: 30px;
  color: white;
  font-weight: normal;
  span {
    background-color: white;
    width: 120px;
    height: 100%;
    padding: 1% 2%;
    border-radius: 30px;
    display: inline-block;
    text-align: center;
    margin-right: 30px;
    color: black;
    font-weight: 700;
  }
  p {
    display: inline;
  }
  @media (max-width: 1120px) {
    & {
      font-size: 2.5vw;
      span {
        margin-right: 15px;
        width: 15vw;
        padding: 0.8%;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      font-size: 3.5vw;
      span {
        margin-right: 15px;
        width: 20vw;
        padding: 0.5% 5%;
      }
    }
  }
}
.form-section {
  flex: 1;
  background-color: white;
  padding: 50px 80px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;
  @media (max-width: 1120px) {
    & {
      padding: 40px 60px;
    }
  }
  @media (max-width: 768px) {
    & {
      padding: 40px;
    }
  }
}
.form-group {
  display: flex;
  //flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1120px) {
    & {
      font-size: 2vw;
    }
  }
  @media (max-width: 768px) {
    & {
      font-size: 3vw;
    }
  }
}
.form-group label {
  width: 30%;
  @media (max-width: 1120px) {
    & {
      width: 23%;
      margin-right: 2%;
    }
  }
}

.form-group input[type="text"],
.form-group select,
.form-group textarea {
  width: 65%;
  padding: 7px 5px;
  border-radius: 4px;
  background: #ebf5fb;
  border: 2px solid #ebf5fb;
  &:focus {
    border: 2px solid #00b2ff;
    background: #fff;
    outline: #00b2ff;
  }
  @media (max-width: 1120px) {
    & {
      width: 70%;
      padding: 5px 7px;
      font-size: 2vw;
    }
  }
  @media (max-width: 768px) {
    & {
      width: 70%;
      padding: 3px 5px;
      font-size: 3vw;
    }
  }
}

.phone-input {
  width: 65%;
  border-radius: 4px;
  background: #ebf5fb;
  border: 2px solid #ebf5fb;
  display: flex;
  justify-content: start;
  align-items: center;
}
.phone-input input[type="text"] {
  width: 30%;
  padding: 7px 5px;
  border-radius: 4px;
  background: #ebf5fb;
  border: 2px solid #ebf5fb;
  text-align: center;
  &:focus {
    border: 2px solid #00b2ff;
    background: #fff;
    outline: #00b2ff;
  }
  @media (max-width: 1120px) {
    & {
      padding: 3px 5px;
      font-size: 2vw;
    }
  }
  @media (max-width: 768px) {
    & {
      padding: 3px 5px;
      font-size: 2.5vw;
    }
  }
}
.setnumberFirst:focus {
  border: none;
  outline: none;
  background-color: #00b2ff;
}
.phone-input span {
  margin: 0 5px;
}
.form-group input[type="radio"],
.form-group input[type="checkbox"] {
  margin-right: 10px;
  margin-bottom: 0.5%;
  &:not(:nth-of-type(1)) {
    margin-left: 20px;
  }
  @media (max-width: 1120px) {
    & {
      margin-right: 5px;
      width: 1.8vw;
      height: 1.8vw;
      &:not(:nth-of-type(1)) {
        margin-left: 20px;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      margin-right: 3px;
      width: 2.8vw;
      height: 2.8vw;
      &:not(:nth-of-type(1)) {
        margin-left: 7px;
      }
    }
  }
}
.privacy {
  justify-content: flex-end;
  button {
    background: none;
    display: inline-block;
    border: none;
    padding: 0 5px;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    cursor: pointer;
  }
}
.form-section > button {
  background: #004fc6;
  color: white;
  width: 100%;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  padding: 30px 0px;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
  @media (max-width: 1120px) {
    & {
      margin-top: 20px;
      font-size: 4vw;
      padding: 20px 0px;
    }
  }
}

.privacyModal {
  font-size: 11px;
  h2 {
    font-size: 11px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  p {
    line-height: 18px;
    margin: 0;
    padding: 0;
  }
}
/* .db_nametoka {
      width: 150% !important;
    } */
