.tenContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 40px;
  box-sizing: border-box;
  // opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  &.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  .sectionSubTitle {
    color: rgba(0, 0, 0, 0.5);
  }
  .sectionTitle {
    margin-bottom: 116px;
    @media (max-width: 1120px) {
      & {
        margin-bottom: 40px;
      }

      &.fade-in {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  .mobileTenSlider {
    width: 100%;
    .slick-slide {
      display: flex;
      justify-content: center;
    }
    .slick-prev,
    .slick-next {
      color: black; // 화살표 색상을 검정색으로 설정
      &:before {
        color: black; // 화살표 아이콘 색상을 검정색으로 설정
      }
    }
  }
  .mobileTenCard {
    p {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 500;
      color: #003d99;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-right: 10px;
      }
      @media (max-width: 1120px) {
        & {
          font-size: 4.5vw;
        }
      }
    }
    .mobileImgBox {
      overflow: hidden;
      background: #fff;
      padding: 10px;
      display: flex;
      align-items: center;
      img {
        width: 100%;
      }
    }
  }
}
