.mainHome {
  overflow-x: hidden;
  > section {
    width: 100%;
    &:not(:nth-of-type(1)) {
      padding: 150px 0;
      position: relative;
    }
    @media (max-width: 1120px) {
      &:not(:nth-of-type(1)) {
        padding: 80px 0;
      }
    }

    .sectionInner {
      width: 1120px;
      margin: 0 auto;
      opacity: 0;
      transform: translateY(20px);
      transition: opacity 0.6s ease-out, transform 0.6s ease-out;
      &.fade-in {
        opacity: 1;
        transform: translateY(0);
      }
    }
    .sectionHi {
      width: 1120px;
      margin: 0 auto;
    }
    @media (max-width: 1120px) {
      .sectionInner,
      .sectionHi {
        width: 90%;
      }
    }
    @media (max-width: 1120px) {
      .sectionInner,
      .sectionHi {
        width: 88%;
      }
    }
    .slider-container {
      position: relative;
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
