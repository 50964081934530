.modal-content {
  width: clamp(280px, 40%, 570px);
  position: fixed;
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* 버튼들을 중앙 정렬 */
  align-items: center;

  &.modal1 {
    .btns {
      display: flex; /* 플렉스 컨테이너 설정 */
      justify-content: space-between; /* 버튼 간 간격 조정 */
      align-items: center; /* 세로 정렬 */
      flex-wrap: wrap; /* 너비 초과 시 다음 줄로 이동 */

      .no-show-button {
        width: 84%;
      }
      .close-button {
        width: 16%;
      }
    }
  }

  .modal-image {
    width: 100%;
    height: auto;
  }
  .no-show-button,
  .close-button {
    cursor: pointer;
  }
}
